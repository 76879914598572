import sprite from "../../images/sprite.svg";
export interface Input {
  "aria-hidden"?: boolean;
  class?: string;
  icon: IconType;
  title?: string;
}
export type IconType = (
  "analytics" |
    "calendar-alt" |
    "calendar-exclamation" |
    "chevron-down" |
    "chevron-left" |
    "chevron-right" |
    "cloud-check" |
    "code-branch" |
    "cog" |
    "copy" |
    "cube" |
    "cubes" |
    "details" |
    "download" |
    "ellipsis-h" |
    "error" |
    "eye" |
    "eye-slash" |
    "git-square" |
    "globe" |
    "information" |
    "lock-alt" |
    "message-dots" |
    "play" |
    "question-circle" |
    "redo" |
    "spinner" |
    "star" |
    "star-filled" |
    "success" |
    "tag" |
    "trash-alt" |
    "unknown" |
    "user-circle" |
    "user-friends" |
    "user-plus" |
    "users-cog" |
    "warning" |
    "x-mark"
);

<span>
  <if(input.title)>
    <svg
      aria-describedby:scoped="title"
      aria-hidden=(input["aria-hidden"] ? "true" : undefined)
      span
      class=["icon", input.class]
    >
      <title id:scoped="title">
        ${input.title}
      </title>
      <use href=`${sprite}#${input.icon}`/>
    </svg>
  </if>
  <else>
    <svg
      aria-hidden=(input["aria-hidden"] ? "true" : undefined)
      span
      class=["icon", input.class]
    >
      <use href=`${sprite}#${input.icon}`/>
    </svg>
  </else>
</span>
style.scss {
  .icon {
    display: inline-flex;
    fill: currentcolor;
    height: 1em;
    position: relative;
    top: 0.16em; /* font-dependent */
    vertical-align: initial; /* reset stoplight */
    width: 1em;
  }
}
