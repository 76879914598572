<!-- use tags -->
static const START_OF_TIME = "Thu, 01 Jan 1970 00:00:00 GMT";
static const END_OF_TIME = "Tue, 19 Jan 2038 03:14:07 GMT";
<sb-top-bar-dropdown icon="message-dots" title="Switch UI" wide>
  <@section>
    <p> Like the new experience?</p>
    <button
      class="button button--secondary ui-switcher__action"
      id="stay-with-new-ui"
      onClick() {
        document.cookie = `clarity=1; expires=${END_OF_TIME}; path=/`;
        document.cookie = `legacy=; expires=${START_OF_TIME}; path=/`;
      }
    >
      Yes! Stay with the new UI
    </button>

    <let/clarityDomain=($global.url.toString().includes(".clarity."))/>

    <if=($global.url.toString().includes(".clarity."))>
      <a
        class="button button--secondary ui-switcher__action"
        href=($global.url.toString().replace(".clarity.", "."))
        id="back-to-old-ui"
      >
        Not yet. Switch back to old UI
      </a>
    </if>
    <else>
      <button
        class="button button--secondary ui-switcher__action"
        id="back-to-old-ui"
        onClick() {
          document.cookie = `clarity=; expires=${START_OF_TIME}; path=/`;
          document.cookie = `legacy=1; expires=${END_OF_TIME}; path=/`;
          document.location.href = "/";
        }
      >
        Not yet. Switch back to old UI
      </button>
    </else>
  </@section>
  <if=$global.pendo>
    <@section>
      <p>
        Take a one-minute survey to tell us about your experience with the new UI
      </p>
      <button class="button" id="take-survey">
        Take survey
      </button>
    </@section>
  </if>
</sb-top-bar-dropdown>
