<!-- use tags -->
export interface Input {
  class?: string;
  icon: "message-dots" | "user-circle";
  title: string;
  section: Marko.RepeatableAttrTag<{
    noPadding?: boolean;
    renderBody: Marko.Body;
  }>;
  wide?: boolean;
}
<id/rootId/>
<id/menuId/>
<let/expanded=false/>

<div
  class="top-bar-dropdown"
  id=rootId
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      expanded = false;
    }
  }
>
  <button
    aria-controls=menuId
    aria-expanded=(expanded ? "true" : "false")
    aria-haspopup="true"
    aria-label=input.title
    class=["top-bar__button", expanded && "top-bar__button--current"]
    onClick() { expanded = !expanded; }
    type="button"
  >
    <sb-icon icon=input.icon title=input.title/>
  </button>
  <div
    class=[
      input.class,
      "top-bar-dropdown__dropdown",
      expanded && "top-bar-dropdown__dropdown--open",
      input.wide && "top-bar-dropdown__dropdown--wide",
    ]
    id=menuId
    onClick() { expanded = false; }
  >
    <for|{ noPadding, renderBody }, index| of=input.section>
      <if=(index > 0)>
        <sb-divider compact/>
      </if>

      <div class=[
        "top-bar-dropdown__item",
        noPadding && "top-bar-dropdown__item--nopadding",
      ]>
        <${renderBody}/>
      </div>
    </for>
  </div>
</div>
<watch-focus for=rootId onOutside() { expanded = false; }/>

<style.scss>
  .top-bar-dropdown {
    display: inline-block;
    position: relative;

    &__dropdown {
      --bg: #{$color-neutral-00};
      --border: #{$color-neutral-30};
      --fg: #{$color-neutral-98};

      background: var(--bg);
      border: 1px solid var(--border);
      border-top: none;
      box-shadow: $elevation-modal;
      color: var(--fg);
      display: none;
      position: absolute;
      right: 0;
      top: calc(100% + 1px);
      z-index: 2;

      &--wide {
        width: 17rem;
      }

      &--open {
        display: block;
      }

      @media (prefers-color-scheme: dark) {
        --bg: #{$color-neutral-98};
        --border: #{$color-neutral-70};
        --fg: #{$color-neutral-00};
      }
    }

    &__item {
      background: inherit;
      border: none;
      color: inherit;
      display: grid;
      padding: $spacing-16;
      text-align: left;

      & p {
        margin-bottom: $spacing-8;
      }

      & .button + .button {
        margin-top: $spacing-8;
      }

      &--nopadding {
        padding: 0;
      }
    }
  }
</style>
