<!-- use tags -->
<lifecycle onMount() {
  const buttons = document.querySelectorAll(
    `button[type="submit"]`,
  ) as NodeListOf<HTMLButtonElement>;
  for (const button of buttons) {
    button.addEventListener("click", () => {
      setTimeout(() => {
        button.disabled = true;
        button.dataset.submitting = "1";
      }, 0);
    });

    const form = (button.form || button.closest("form")) as HTMLFormElement;

    const reset = () => {
      button.disabled = false;
      delete button.dataset.submitting;
    };

    form.addEventListener("input", reset);
    form.addEventListener("change", reset);
  }
}/>
<!-- see https://issues.chromium.org/issues/41031275 -->
<style.scss>
  [data-submitting] {
    cursor: wait !important;
  }
</style>
